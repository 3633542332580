import React, { Component } from "react";

import { ReactComponent as Logo } from "../images/cabin-logo.svg";
import { connect } from "react-redux";

class Footer extends Component {
	render() {
		return (
			<footer className={this.props.stateClass + " footer"}>
				<div className="container container--narrow">
					<div className="footer__wrapper">
						<div className="footer__col">
							<div className="footer__logo">
								<Logo
									height="20"
									width="20"
									preserveAspectRatio="none"
									className="header__logo"
								/>
							</div>
							<span>
								<a href="mailto:hello@cabin.com.sg">hello@cabin.com.sg</a>
							</span>
							<span>+65 3158 5495</span>
							<br />
							<span>380 Jalan Besar</span>
							<span>#06-02 ARC 380</span>
							<span>Singapore 209000</span>
						</div>
						<div className="footer__col">
							<span>Cabin Pte. Ltd.</span>
							<span>UEN: 201714875M</span>
							<span>Filing Agent Number: FA20170616</span>
							<span>GST Registration Number: 201714875M</span>
						</div>
						<div className="footer__col">
							<span>Cabin Corporate Services Pte. Ltd.</span>
							<span>UEN: 202218514N</span>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

const mapStateToProps = (state) => ({
	stateClass: state.footer.stateClass,
});

export default connect(mapStateToProps, null)(Footer);
