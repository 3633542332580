import './FloatingWhatsappButton.css'; // Create this file for styling

import React from 'react';

const FloatingWhatsappButton = ({
  onClick
}) => {
  return (
    <div className="floating-button">
      <button 
        style={{
          background: 'none',
          height: '48px',
          width: '48px',
          padding: '4px' 
        }}
        onClick={onClick}>
        <img 
          style={{objectFit: 'contain', height: '28px', width: '28px'}}
          src={require('images/icons/whatsapp-icon.png')} srcset={require('images/icons/whatsapp-icon@2x.png') + ' 2x'} />
      </button>
    </div>
  );
};

export default FloatingWhatsappButton;
