import './sass/app.sass';

import { BrowserRouter } from 'react-router-dom';
import FloatingWhatsappButton from './components/FloatingWhatsappButton/FloatingWhatsappButton';
import Footer from './components/Footer';
// import GoogleTagManager from './components/GoogleTagManager';
import Header from './components/Header';
import NotificationsSystem from 'reapop';
import React from 'react';
import RenderSwitch from './Switch';
import ScrollToTop from './components/ScrollToTop';
import { routes } from './routes';
import svg4everybody from 'svg4everybody';
import theme from 'reapop-theme-wybo';

const WHATSAPP_NUMBER = '6590121184';

class App extends React.Component {
  componentDidMount() {
    require('viewport-units-buggyfill').init({
      force: false,
      refreshDebounceWait: 150,
    });

    svg4everybody();
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="page">
            <Header routes={routes.filter((route) => route.forNavBar)} />
            <div className="page__content">
              <NotificationsSystem theme={theme} />
              <RenderSwitch />
            </div>
            <Footer />
            <FloatingWhatsappButton onClick={() => {
              window.open(`https://wa.me/${WHATSAPP_NUMBER}`, '_blank');
            }}/>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
